.tf-shutter {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  width: 100%;
  height: 100%;
  background-color: #0000003d;
  position: fixed;
  top: 0;
  left: 0;
}

.tf-modal-window {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tf-modal-window-header {
  width: 100%;
  color: #f3f3f3;
  background-color: #1e87f0;
  margin: 0;
  padding: 12px 16px;
  display: block;
}

.tf-modal-window-close {
  color: #eee;
  float: right;
  cursor: pointer;
  margin: 4px 18px 0 0;
  padding: 0;
  font-size: 24px;
  font-weight: bold;
}

.tf-modal-window-close:hover {
  color: #f0506e;
}

.tf-modal-window-content {
  background-color: #f8f8f8;
  padding: 16px;
  display: block;
  overflow-y: scroll;
}

.tf-modal-window-footer {
  max-height: 100px;
  overflow: none;
  text-align: right;
  background-color: #f4f4f4;
  border-top: 1px solid #e1e1e1;
  padding: 12px;
  display: block;
}

@media (max-width: 600px) {
  .tf-modal-window {
    width: 100%;
  }

  .tf-modal-window-content {
    max-height: 85%;
    padding: 8px;
  }
}

/*# sourceMappingURL=index.d89af800.css.map */
