.tf-shutter {
    background-color: #0000003d;
    backdrop-filter: blur(4px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.tf-modal-window {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.tf-modal-window-header {
    display: block;
    margin: 0;
    padding: 12px 16px;
    width: 100%;
    background-color: #1e87f0;
    color: #f3f3f3;
}

.tf-modal-window-close {
    color: #eee;
    float: right;
    padding: 0;
    margin: 0;
    font-size: 24px;
    margin-right: 18px;
    margin-top: 4px;
    font-weight: bold;
    cursor: pointer;
}

.tf-modal-window-close:hover {
    color: #f0506e;
}

.tf-modal-window-content {
    display: block;
    padding: 16px;
    background-color: #f8f8f8;
    overflow-y: scroll;
    /* max-height: 80%; */
}

.tf-modal-window-footer {
    display: block;
    padding: 12px;
    max-height: 100px;
    background-color: #f4f4f4;
    overflow: none;
    text-align: right;
    border-top: 1px solid #e1e1e1;
}

@media (max-width: 600px) {

    .tf-modal-window {
        width: 100%;
    }
    
    .tf-modal-window-content {
        padding: 8px;
        max-height: 85%;
    }
}